export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63')
];

export const server_loads = [0,2,3,4,5,6,8];

export const dictionary = {
		"/(marketing)": [51,[14]],
		"/(marketing)/about": [52,[14]],
		"/(marketing)/blog": [53,[14,15]],
		"/(marketing)/blog/posts/[slug=blogpost]": [54,[14,15]],
		"/(marketing)/changelog": [55,[14]],
		"/(authorised)/console/account": [17,[2,3,4]],
		"/(authorised)/console/account/api": [18,[2,3,4]],
		"/(authorised)/console/account/settings": [19,[2,3,4,5]],
		"/(authorised)/console/account/subscriptions": [~20,[2,3,4]],
		"/(authorised)/console/instance/auditing": [21,[2,3]],
		"/(authorised)/console/instance/checks": [22,[2,3]],
		"/(authorised)/console/instance/general": [23,[2,3]],
		"/(authorised)/console/instance/initialise": [24,[2,3]],
		"/(authorised)/console/instance/logging": [25,[2,3]],
		"/(authorised)/console/org/confirm_org_deleted": [26,[2,3]],
		"/(authorised)/console/org/danger_zone": [27,[2,3]],
		"/(authorised)/console/org/danger_zone/delete_org": [~28,[2,3]],
		"/(authorised)/console/org/overview": [29,[2,3]],
		"/(authorised)/console/org/users": [~30,[2,3]],
		"/(marketing)/contact_us": [56,[14]],
		"/(docs)/docs": [42,[9]],
		"/(docs)/docs/[...slug=doc]": [43,[9]],
		"/(marketing)/features": [57,[14]],
		"/(authorised)/github/app_setup": [31,[2,6]],
		"/(login)/login": [~44,[10,11]],
		"/(login)/login/authenticate": [45,[10,11,12]],
		"/(login)/login/current_password_error": [46,[10,11]],
		"/(login)/login/forgot_password": [47,[10,11]],
		"/(login)/login/oauth": [48,[10,11]],
		"/(login)/login/register": [49,[10,11,13]],
		"/(backend)/oauth2/auth": [~40],
		"/(authorised)/onboarding/select_plan": [32,[2,7]],
		"/(authorised)/onboarding/subscriptions/confirm": [~33,[2,7]],
		"/(authorised)/onboarding/subscriptions/do_assignment": [~34,[2,7]],
		"/(authorised)/onboarding/subscriptions/do_subscribe": [~35,[2,7]],
		"/(authorised)/onboarding/subscriptions/on_succeeded": [~36,[2,7]],
		"/(marketing)/partners": [58,[14]],
		"/(marketing)/pricing": [59,[14]],
		"/(marketing)/sales": [60,[14]],
		"/(marketing)/security": [61,[14]],
		"/(login)/sign_out": [50,[10]],
		"/(authorised)/submit_feedback": [37,[2]],
		"/(marketing)/support": [62,[14]],
		"/(demos)/testpages/cobe": [41],
		"/(authorised)/testpages/gh_token_refresh": [38,[2,8]],
		"/(authorised)/testpages/logevents/view": [39,[2]],
		"/(policies)/[slug=policydoc]": [63,[16]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';